import React, { useEffect, useState } from "react";
import { GetMachineData } from "../../Service/MachineApi";
import { useNavigate } from "react-router-dom";
import Clock from "react-live-clock";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Machine.css";
import HeaderLogin from "../Common/Header/HeaderLogin";
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "../Common/Footer/Footer";

export default function Machine() {
  const navigate = useNavigate();

  const [machinedata, setMachinedata] = useState([]);
  const [loading, setLoading] = useState(false);

  // const location = useLocation();
  // let Building = new URLSearchParams(location.search).get("Building");

  const gettingMachineData = async () => {
    setLoading(true);
    let res = await GetMachineData();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setMachinedata(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (Building) {
    gettingMachineData();
    // }
  }, []);

  return (
    <>
      {loading === true ? (
        <div className="loader">
          <LinearProgress />
        </div>
      ) : (
        <>
          <div className="mainWithFooter">
            <div className="machineMainContainer">
              <HeaderLogin
                timedate={
                  <Clock
                    format={"HH:mm:ss| DD-MM-YYYY"}
                    ticking={true}
                    timezone={"asia/Karachi"}
                  />
                }
              />
              <div className="gatherChart">
                {machinedata?.map((bdata, i) => {
                  const meterRead = bdata?.meterReadings;
                  return (
                    <div
                      key={i}
                      className="gaugechartareamachine"
                      onClick={() => {
                        navigate(`/dashboard/Dep?MQTT_ID=${bdata?.MQTT_ID}`, {
                          state: { roomName: bdata?.machineName }
                        });
                      }}
                    >
                      <div className="machinename centerName">
                        {bdata?.machineName}
                      </div>
                      <div className="machineNameID centerName deviceid">
                        {bdata?.Device_ID}
                      </div>

                      {meterRead[meterRead.length - 1]?.flow && (
                        <div className="flowarea">
                          Flow: {parseInt(meterRead[meterRead.length - 1]?.flow).toLocaleString()} m3/hr
                        </div>
                      )}
                      {meterRead[meterRead.length - 1]?.total && (
                        <div className="flowarea">
                          Total Flow:{" "}
                          {parseInt(
                            meterRead[meterRead.length - 1]?.total
                          ).toLocaleString()}{" "}
                          m3
                        </div>
                      )}
                      {meterRead[meterRead.length - 1]?.linetemp && (
                        <div className="flowarea">
                          Line Temp:{" "}
                          {parseInt(
                            meterRead[meterRead.length - 1]?.linetemp
                          ).toLocaleString()}{" "} 
                          °C
                          
                        </div>
                      )}
                      {meterRead[meterRead.length - 1]?.date && (
                        <div className="datetime">
                          Date Time:{" "}
                          {
                            meterRead[meterRead.length - 1]?.time
                          }{" "} | {
                            meterRead[meterRead.length - 1]?.date
                          }
                          
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <Footer
              comp={"Omni Telemetry USA"}
            />
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );
}
